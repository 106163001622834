<template>
  <div class="branch">
    <pageHeader />

    <div class="branch-content">
      <v-container fluid class="">
        <v-row>
          <v-col cols="12" md="9">
            <h1 class="main-color mb-4">{{ branchInfo.salon.name }}</h1>
            <hr class="branch-hr" />
            <v-row class="mt-4">
              <v-col col="12" sm="4">
                <h2 class="font-bold">العنوان</h2>
                <div class="d-flex align-baseline">
                  <span>
                    <v-icon class="icon" small>mdi-check-outline</v-icon>
                  </span>
                  <div class="text">
                    <p class="font-bold mt-4 mb-0 second-color">
                      {{ branchInfo.name }}
                    </p>
                    <p>{{ branchInfo.formatted_address }}</p>
                  </div>
                </div>
              </v-col>
              <v-col col="12" sm="8" class="pa-0">
                <v-row>
                  <v-col cols="12" sm="8">
                    <Map
                      :lat="branchInfo.lat"
                      :lng="branchInfo.lng"
                      v-if="branchInfo.lat && branchInfo.lng"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-card
                      class="ma-4 salon-cart"
                      height="230px"
                      width="230px"
                    >
                      <div class="img-div">
                        <img
                          v-if="branchInfo && branchInfo.salon"
                          :src="branchInfo.salon.main_image"
                          height="230px"
                          width="230px"
                          alt=""
                        />
                      </div>
                      <div
                        class="content"
                        v-if="branchInfo && branchInfo.salon"
                      >
                        <p class="name my-2">{{ branchInfo.salon.name }}</p>
                        <!-- <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon> -->
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <staticCartMenu
              :cartItems="cartItems"
              :totalPrice="totalPrice"
              @deleteCartItem="deleteCartItem"
            >
              <v-btn class="login-btn" large @click="confirmReservation"
                >تاكيد الحجز

                <v-icon color="#a53860" class="cart-btn-icon"
                  >mdi-chevron-left</v-icon
                >
              </v-btn>
            </staticCartMenu>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import staticCartMenu from "@/components/staticCartMenu.vue";
import pageHeader from "@/components/pageHeader.vue";

import Map from "@/components/Map.vue";

export default {
  name: "branch",
  components: { Map, staticCartMenu, pageHeader },

  data() {
    return {
      cartItems: [],
      cartItemsIds: [],
      cartId: "",
      totalPrice: "",
      branchId: "",
      branchInfo: {},
      loggedIn: localStorage.getItem("loggedIn"),
    };
  },
  methods: {
    getBranchInfo() {
      this.$http
        .get(this.base_url + "/front/getBranchInfo/" + this.branchId, {})
        .then((response) => {
          console.log(response);
          this.branchInfo = response.data.data;
        });
    },
    updateCart(cartId, cartItems, totalPrice, cartItemsIds) {
      console.log("updated");
      this.cartId = cartId;
      this.cartItems = cartItems;
      this.totalPrice = totalPrice;
      this.cartItemsIds = cartItemsIds;
    },
    showCart() {
      if (localStorage.getItem("cartId")) {
        this.cartId = localStorage.getItem("cartId");
      }
      if (this.cartId) {
        this.$http
          .get(this.base_url + "/front/showCart?cartID=" + this.cartId, {})
          .then((response) => {
            console.log(response);
            this.cartId = response.data.data.cartID;
            this.cartItems = response.data.data.items;
            this.totalPrice = response.data.data.totalPrice;
            this.cartItemsIds = [];

            this.cartItems.forEach((item) => {
              this.cartItemsIds.push(item.id);
            });
          });
      }
    },
    deleteCartItem(itemId) {
      this.$http
        .get(
          this.base_url +
            "/front/removeFromCart/" +
            itemId +
            "?cartID=" +
            this.cartId,
          {}
        )
        .then((response) => {
          this.cartItems = response.data.data.items;
          this.totalPrice = response.data.data.totalPrice;
          this.cartItemsIds = [];

          this.cartItems.forEach((item) => {
            this.cartItemsIds.push(item.id);
          });

          this.$refs.cartMenu.updateCart(
            this.cartId,
            this.cartItems,
            this.totalPrice,
            this.cartItemsIds
          );
        });
    },
    confirmReservation() {
      if (this.loggedIn == "true") {
        this.$router.push("/reserve/steps?step=1");
      } else {
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  mounted() {
    this.showCart();
    this.branchId = this.$route.query.id;

    this.getBranchInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.header {
  background-image: url("../assets/bg.jpg");
  border-radius: 0 0 0 40px;
  background-size: cover;
  height: 300px !important;
  position: relative;
  padding-right: 50px;
  padding-left: 50px;
  .nav-actions {
    margin-top: 6rem;
  }
  .main-head {
    margin-top: 2rem !important;
  }
  .search-sec {
    max-width: 80%;
    text-align: center;
    margin: 0 auto !important;
    margin-top: 4rem !important;
  }
}
.branch-content {
  margin: 50px 0;
  margin-right: 50px;
  margin-left: 50px;
  .icon {
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 50%;
    text-align: center;
    margin: 5px;
  }
  .branch-hr {
    border-top: 1px solid #ccc;
  }
}
.cart {
  width: 80%;
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 25px;
  border-radius: 0 0 0 40px;
  .cart-hr {
    margin: 15px 0;
  }
  .cart-head {
    h3 {
      font-size: 2rem;
    }
    hr {
      width: 30%;
      margin: 0 auto;
    }
  }

  .salon-name {
    font-size: 14px;
  }
  .cart-total {
    .num {
      font-size: 2rem !important;
    }
  }

  .cart-btn-icon {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.salon-cart {
  .img-div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    img {
      border-radius: 0 40px 0 0;
      object-fit: fill;
      width: 100%;
    }
  }
  .content {
    color: #ffffff;
    padding: 0 10px;
    position: absolute;
    z-index: 3;
    bottom: -16px;
    /* right: 10%; */
    background-color: #a53860;
    width: 90%;
    border-radius: 2px 15px 2px 2px;
    .name {
      text-align: end;
    }
  }
}

@media (max-width: 600px) {
  .branch-content {
    margin: 0 0;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .branch-content {
    margin: 0 0;
    margin-right: 0;
    margin-left: 0;
  }
}
</style>
